<template>
	<v-sheet v-if="id">
		<h4>{{project.internal_name}}</h4>
		<currently-recruiting-tile :id="id"></currently-recruiting-tile>
		<secondary-navigation show-back>
            <template v-slot:main>
				<nav-item
                    link="/currently-recruiting"
					text="All Currently Recruiting"
					icon="mdi-star"
				></nav-item>
            </template>
        </secondary-navigation>
	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@c/navigation/Item";
import CurrentlyRecruitingTile from "@c/edits/CurrentlyRecruitingTile";

export default {
	name: "EditProjectRecruiting",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
    },
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
    components: {
		SecondaryNavigation,
        NavItem,
        CurrentlyRecruitingTile
	}
}
</script>